import { FC } from 'react';
import { allTenses, Mood, Tense } from './useVerbs';

export type Modes = 'word' | 'verbs' | 'phrase';

export interface SettingsParams {
  mode: Modes;
  mood: Mood;
  tenses: Tense[];
  wordLimit: number;
  specificVerb: string;
}

interface Props {
  isOpen: boolean;
  foundSpecificVerb: boolean;
  settings: SettingsParams;
  onChange?: (settings: SettingsParams) => void;
}

const Settings: FC<Props> = ({
  isOpen,
  onChange,
  foundSpecificVerb,
  settings,
}) => {
  const { mode, mood, tenses, wordLimit, specificVerb } = settings;

  const onChangeHandler = (setting: Partial<SettingsParams>) => {
    onChange &&
      onChange({
        mode,
        mood,
        tenses,
        wordLimit,
        specificVerb,
        ...setting,
      });
  };

  const modeChangeHandler = (mode: Modes) => {
    onChangeHandler({ mode });
  };

  const moodChangeHandler = (mood: Mood) => {
    onChangeHandler({ mood });
  };

  const wordLimitChangeHandler = (wordLimit: number) => {
    onChangeHandler({ wordLimit });
  };

  const specificWordHandler = (specificVerb: string) => {
    onChangeHandler({ specificVerb });
  };

  const toggleTense = (tense: Tense) => {
    if (tenses.includes(tense)) {
      const newTenses = tenses.filter((t) => t !== tense);
      onChangeHandler({ tenses: newTenses });
      return newTenses;
    }
    const newTenses = [...tenses, tense];
    onChangeHandler({ tenses: newTenses });
    return newTenses;
  };

  return (
    <div className={`settings ${isOpen ? 'open' : ''}`}>
      <div
        style={{ marginTop: '80px', display: 'flex', flexDirection: 'column' }}
        onChange={(e) => modeChangeHandler((e.target as any).value)}
      >
        <p>Mode:</p>
        <div style={{ marginBottom: '10px' }}>
          <input
            id="radio-verb"
            type="radio"
            name="mode"
            value="verbs"
            defaultChecked
          />
          <label htmlFor="radio-verb">Verb conjugations</label>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <input id="radio-word" type="radio" name="mode" value="word" />
          <label htmlFor="radio-word">Random words (Beta)</label>
        </div>
        <div>
          <input id="radio-word" type="radio" name="mode" value="phrase" />
          <label htmlFor="radio-word">Random phrases (Beta)</label>
        </div>
      </div>
      <div>
        <p>Word limit:</p>
        <input
          type="number"
          min={1}
          value={wordLimit}
          onChange={(e) => wordLimitChangeHandler(Number(e.target.value))}
        />
      </div>
      <div
        className={mode === 'verbs' ? '' : 'disabled'}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div>
          <p>Practice specific verb:</p>
          <input
            placeholder="i.e.: poner"
            type="text"
            value={specificVerb}
            style={{
              border: '2px solid',
              borderColor: `${foundSpecificVerb ? '#37fb37' : '#888'}`,
              outlineColor: `${foundSpecificVerb ? '#37fb37' : '#888'}`,
            }}
            onChange={(e) => specificWordHandler(e.target.value)}
          />
        </div>
        <div onChange={(e) => moodChangeHandler((e.target as any).value)}>
          <p>Mood:</p>
          <div style={{ marginBottom: '10px' }}>
            <input
              id="check-indicative"
              type="checkbox"
              name="mood"
              value="Indicative"
              defaultChecked
            />
            <label htmlFor="check-indicative">Indicative</label>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <input
              id="check-subjunctive"
              type="checkbox"
              name="mood"
              value="Subjunctive"
            />
            <label htmlFor="check-subjunctive">Subjunctive</label>
          </div>
        </div>
        <div>
          <p>Tenses:</p>
          {allTenses.map((tense) => (
            <div style={{ marginBottom: '10px' }} key={tense}>
              <input
                readOnly
                id={`check-${tense}`}
                type="checkbox"
                name="tense"
                value={tense}
                checked={tenses.includes(tense)}
                onClick={() => toggleTense(tense)}
              />
              <label htmlFor="check-present">{tense}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Settings;
